import NavBtn from "./NavBtn"
import { useState } from "react";
import { getAuth, signOut } from "firebase/auth"
import appFirebase from "../../firebase-config";
import { useUser } from "../../context/UserContext";
import { useHistory } from 'react-router-dom';


const auth = getAuth(appFirebase)

const SiderNav = () => {
    const [menuVisible, setMenuVisible] = useState(false);
    const { logout, userData } = useUser()
    const history = useHistory();


    const toggleMenu = () => {
        setMenuVisible(!menuVisible);
    };

    const handleLogout = (e) => {
        e.preventDefault()
        signOut(auth)
            .then(() => {
                logout()
                console.log("Usuario desconectado exitosamente");
                history.push('/');
            })
            .catch((error) => {
                console.error("Error al desconectar al usuario:", error);
            });
    };

    return (
        <nav class="navegacion">
            <ul>
                <h4>Main menu</h4>
                <hr />
                <li>
                    <NavBtn lk="/Dashboard/home" ic="bi bi-house" sp="Home" />
                </li>
                <li>
                    <NavBtn lk="/Dashboard/packs" ic="bi bi-boxes" sp="Starter packs" />
                </li>
                <li>
                    <NavBtn lk="/Dashboard/My-net" ic="bi bi-people" sp="My net" />
                </li>
                <li>
                    <NavBtn lk="/Dashboard/My-net-genealogy" ic="bi bi-diagram-3" sp="Genealogy" />
                </li>
                <li>
                    <NavBtn lk="/Dashboard/benefits" ic="bi bi-graph-up-arrow" sp="benefits" />
                </li>
                <li>
                    <NavBtn lk="/Dashboard/withdrawals" ic="bi bi-person-vcard" sp="withdrawals" />
                </li>
                <li>
                    <NavBtn lk="/Dashboard/internal-transfers" ic="bi bi-cash-coin" sp="Internal transfers" />
                </li>
                <li>
                    <NavBtn lk="/Dashboard/bill-payment" ic="bi bi-receipt" sp="Bill Payment" />
                </li>
                <li>
                    <NavBtn lk="/Dashboard/E-comerce" ic="bi bi-bag" sp="E-comerce" />
                </li>
                <li>
                    <NavBtn lk="/Dashboard/cooming-soon" ic="bi bi-archive" sp="Tools" />
                </li>
                <li>
                    <NavBtn lk="/Dashboard/Support" ic="bi bi-exclamation-octagon" sp="Support" />
                </li>
                <li>
                    <NavBtn lk="/Dashboard/Profile" ic="bi bi-person-gear" sp="Edit profile" />
                </li>
                {userData?.deuda != 0 && (
                    <li>
                        <NavBtn lk="/Dashboard/corporate-credit" ic="bi bi-credit-card-2-front" sp="Corporate credit" />
                    </li>
                )}
                <li onClick={(e) => handleLogout(e)}>
                    <NavBtn lk={""} ic="bi bi-box-arrow-right" sp="Logout" />
                </li>
            </ul>
        </nav>
    )
}

export default SiderNav