import { useEffect, useState } from "react"
import logoUSDT from "../../../Assets/Images/Logos/usdt.png";
import { useUser } from "../../../context/UserContext";
import appFirebase from "../../../firebase-config";
import { getDatabase, ref, get, query, orderByChild, equalTo } from "firebase/database";
import "./DeudaDetails.css";


const DeudaDetails = () => {
    const [historial, setHistorial] = useState([])
    const { userData } = useUser()
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        fetchHistorial(userData.userName).then(result => {
            setHistorial(result)
            setIsLoading(!isLoading)
        })
    }, [])

    const fetchHistorial = async (userName) => {
        try {
            const db = getDatabase(appFirebase);
            const dbRef = ref(db, "history");

            const snapshot = await get(
                query(
                    dbRef,
                    orderByChild("abono"),
                    equalTo(true)
                )
            );

            if (snapshot.exists()) {
                // Filtra los resultados para mantener solo los elementos donde `abono` es `true`
                const data = snapshot.val();
                const filteredData = Object.values(data).filter(item => item.userName === userData.userName);

                return filteredData;
            } else {
                console.log("No data found");
                return null;
            }
        } catch (error) {
            console.error("Error fetching historial:", error);
            return null;
        }
    };


    return (
        <section className="contenido DeudaDetails">
            <header className="titulos">
                <i className="bi bi-person-gear"></i>
                <span>Debt</span>
            </header>
            <section className="contenido">
                <div className="walletOpcion">
                    <h3>Total debt</h3>
                    <div>
                        <img src={logoUSDT} alt="usdt_logo" />
                        <span>{(userData?.deuda || 0).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(',', '.')} USDT</span>
                    </div>
                </div>

                <section className="historial">
                    <header>
                        <h2><i class="bi bi-clock-history"></i> Payment history</h2>
                    </header>

                    <table>
                        <thead>
                            <tr>
                                <th>
                                    Hour
                                </th>
                                <th>
                                    Date
                                </th>
                                <th>
                                    Amount
                                </th>
                                <th>
                                    Concept
                                </th>
                                <th>
                                    Transmitter
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {isLoading ? (
                                <div className="spinner"></div>
                            ) : (
                                historial ? (
                                    historial.map((item) => (
                                        <tr key={item}>
                                            <td >{item.hora}</td>
                                            <td >{item.date}</td>
                                            <td >{item.cantidad} USDT</td>
                                            <td >{item.concepto} </td>
                                            <td >{item.emisor}</td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="6">No orders</td>
                                    </tr>
                                )
                            )}
                        </tbody>
                    </table>
                </section>

            </section>
        </section>
    )
}

export default DeudaDetails