import logoUSDT from "../../../Assets/Images/Logos/usdt.png";
import "./TransferenciaInterna.css";
import TextField from "../../../components/TextInput/TextField";
import { Link } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import Alert from "../../../components/AlertMsg/MensajeAlerta";
import InputNip from "../../../components/NipModal/InputNip";
import Common from "../../../components/js/Common";
import { useUser } from "../../../context/UserContext";


const TransferenciaInterna = (props) => {
  const { userData } = useUser()
  const alertRef = useRef()
  const nipRef = useRef()
  const [state, setState] = useState({ cantidad: 50, userName: "pp", seleccionado: 1, isLoading: true })
  const [destinatarioData, setDestinatarioData] = useState({});
  const [visibleConfirmarTransModal, setConfirmarTransModal] = useState(false)


  function hasMoreThanTwoDecimals(num) {
    const str = num.toString();
    const decimalIndex = str.indexOf(".");
    return decimalIndex !== -1 && str.slice(decimalIndex + 1).length > 2;
  };

  const changeState = (name, value) => {
    setState(prevState => ({
      ...prevState,
      [name]: value
    }))
  }

  const changeSeleccion = (opc) => {
    changeState("seleccionado", opc)
  }

  const fetchDestinatario = async () => {
    const userRepo = new Common();
    try {
      const user = await userRepo.getUserDataByName(state.userName);
      if (user) {
        setDestinatarioData(user)
        return true;
      }
    } catch (error) {
      return false;
    }
  };

  useEffect(() => {
    if (!userData) {
      changeState("isLoading", true)
    } else {
      changeState("isLoading", false)
    }
  }, [userData]);



  const validacion = async () => {
    const wallet = state.seleccionado == 1 ? userData.walletDiv : userData.walletCom
    const trasnferenciaMinima = 50

    const errores = {
      invalidValue: "The value you entered is not valid",
      notUser: "The user does not exist",
      completeProfile: "Complete your profile to be able to withdraw",
      insufficientBalance: "You do not have enough balance to withdraw this amount",
      minWithdrawal: `The minimum withdrawal amount is ${trasnferenciaMinima} USDT`,
      minBalanceAfterWithdraw: "After withdrawing you need to have at least 25 USDT remaining",
      retiroActivo: "You already have a pending withdrawal",
    };

    const mostrarError = (mensaje) => {
      alertRef.current.showAlert(mensaje, false)
      return false;
    };

    if (isNaN(parseFloat(state.cantidad)) || hasMoreThanTwoDecimals(state.cantidad)) {
      return mostrarError(errores.invalidValue);
    }
    if (state.cantidad > wallet) {
      return mostrarError(errores.insufficientBalance);
    }

    if (state.cantidad < trasnferenciaMinima) {
      return mostrarError(errores.minWithdrawal);
    }

    if (state.seleccionado === 1 && userData.walletDiv - state.cantidad < 25) {
      return mostrarError(errores.minBalanceAfterWithdraw);
    }

    if (!state.userName) {
      return mostrarError(errores.invalidValue);
    }

    const exists = await fetchDestinatario();
    if (!exists) {
      return mostrarError(errores.notUser);
    }

    return true;
  }

  const comenzarProceso = () => {
    validacion().then(result => {
      if (result) {
        openCloseTransModal()
      }
    })
  };

  const openCloseTransModal = () => {
    setConfirmarTransModal(!visibleConfirmarTransModal)
  }

  const transferir = () => {
    if (state.seleccionado == 1) {
      userData.walletDiv -= Number(state.cantidad)
    } else {
      userData.walletCom -= Number(state.cantidad)
    }

    const userRepo = new Common()
    userRepo.editAnyUser(userData).then(() => {

      userRepo.getUserDataByName(state.userName).then(user => {

        const transferencia = Number(state.cantidad - (state.cantidad * .03))
        user.walletDiv += Number(transferencia)

        userRepo.editAnyUser(user).then(() => {
          if (alertRef.current)
            alertRef.current.showAlert("Transfer sent successfully", true)
          openCloseTransModal()
        })
        userRepo.saveInHistory(user.userName, state.cantidad, "Internal transfer", userData.userName, "")
      })
    })
  }

  return (
    <section className="contenido Retiros">
      {state.isLoading ? (
        <div className="spinner"></div>
      ) : (
        <>
          {visibleConfirmarTransModal && (
            confirmarTransaccionModal()
          )}
          <Alert ref={alertRef} />
          <InputNip ref={nipRef} proceso={transferir} />
          <section className="titulos titulo-re">
            <i className="bi bi-cash-coin"></i>
            <span>Internal Transactions</span>
          </section>

          <section className="contenido">
            <section className="retirar">
              <p className="titulo">Select your wallet</p>
              <div className="wallets">
                {WalletOpcion("Divident wallet", userData.walletDiv, 1)}
                {WalletOpcion("Comission wallet", userData.walletCom, 2)}
              </div>
              <TextField ti={"User"} value={state.userName} name={"userName"} changeState={changeState} pl={"Ej:user123"} />
              <TextField ti={"Amount to transfer(USDT)"} value={state.cantidad} name={"cantidad"} changeState={changeState} pl={0} />
              <button className="boton4" onClick={comenzarProceso}><span>Transfer</span></button>
            </section>

            <section className="notas">
              <p className="textoM2">Important notes:</p>
              <p className="textoM"><li>You need to have your USDT(TRC20) wallet address registered. If you don't have it yet,<Link to="Profile" className="link"> click here</Link></li></p>
              <p className="textoM"><li>Withdrawal requests: minimum amount of 50 USDT required for the <span>dividend wallet</span> and minimum amount of 10 USDT required for <span>commission wallet</span>.</li></p>
              <p className="textoM"><li>All requests will be approved only <span>Monday through Friday</span> starting at 12:00 a.m. (Miami time).</li></p>
              <p className="textoM"><li>Withdrawal <span>cost of 3% </span>for the total amount requested (Administrative expenses).</li></p>
              <p className="textoM"><li>When a withdrawal is requested the dividend wallet <span>must have at least 25 USDT</span>.</li></p>
            </section>
          </section>
        </>
      )}

    </section >
  )

  function WalletOpcion(titulo, wallet, opc) {
    return (
      <div onClick={() => changeSeleccion(opc)} className={state.seleccionado == opc ? "seleccionado walletOpcion" : "walletOpcion"}>
        <h3>{titulo}</h3>
        <div>
          <img src={logoUSDT} alt="usdt_logo" />
          <span>{(wallet || "0.00").toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(',', '.')}</span>
        </div>
      </div>
    )
  }
  function confirmarTransaccionModal() {

    const openNipModal = () => {
      nipRef.current.show()
      openCloseTransModal()
    }

    return (
      <section className="confirmarTransaccionModal">
        <div className="overlay"></div>
        <section className="confirmarTransaccion-contenido">
          <header>
            <h1>Transaction details</h1>
            <button onClick={openCloseTransModal}><i class="bi bi-x"></i></button>
          </header>
          <div className="datosDeTranferencia">
            <div className="titulo"><i class="bi bi-person"></i><span>Addressee</span></div>
            <h5>Username: <span>{destinatarioData.userName}</span></h5>
            <h5>Name: <span>{destinatarioData.firstName}</span></h5>
            <h5>Last name: <span>{destinatarioData.lastName}</span></h5>
            <h5>email: <span>{destinatarioData.email}</span></h5>
          </div>
          <div className="datosDeTranferencia">
            <div className="titulo"><i class="bi bi-coin"></i><span>Amount details</span></div>
            <h5>Selected wallet: <span>{state.seleccionado == 1 ? ("Dividend") : ("Commision")}</span></h5>
            <h5>Amount: <span>{state.cantidad.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(',', '.') || "0.00"} USDT</span></h5>
            <button onClick={openNipModal}><i class="bi bi-send"></i>Transfer</button>
          </div>
        </section>
      </section>
    )
  }

}

export default TransferenciaInterna