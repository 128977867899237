import React, { useEffect, useState,forwardRef,useImperativeHandle } from 'react';
import "./AlertMsj.css"


const MensajeAlerta = forwardRef(({duration = 3000 }, ref) => {
    const [state, setState] = useState({
        visible:false,
        texto:"",
        success:false
    });
    const [timeLeft, setTimeLeft] = useState(duration / 1000);

    const handleChange = (name,value) => {
        setState(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    useImperativeHandle(ref, () => ({
        showAlert: (texto, success) => {
            handleChange("visible",true)
            handleChange("texto",texto)
            handleChange("success",success)
            setTimeLeft(duration / 1000); // Reinicia el temporizador
        }
    }));

    useEffect(() => {
        let timer;
        if (state.visible) {
            timer = setTimeout(() => handleChange("visible",false), duration);
        }
        return () => clearTimeout(timer);
    }, [state.visible, duration]);

    useEffect(() => {
        if (state.visible && timeLeft > 0) {
            const countdown = setInterval(() => {
                setTimeLeft((prev) => prev - 1);
            }, 1000);
            return () => clearInterval(countdown);
        }
    }, [state.visible, timeLeft]);

    const close = () => {
        handleChange("visible",false)
    }

    if (!state.visible) return null;

    return (
        <div className={"alert " + (state.success ? "success" : "error")}>
            <div className="icon">
                {state.success ?
                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#e8eaed"><path d="M382-240 154-468l57-57 171 171 367-367 57 57-424 424Z" /></svg>
                    :
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" viewBox="0 0 24 24" height="24" fill="none"><path fill="#393a37" d="m13 13h-2v-6h2zm0 4h-2v-2h2zm-1-15c-1.3132 0-2.61358.25866-3.82683.7612-1.21326.50255-2.31565 1.23915-3.24424 2.16773-1.87536 1.87537-2.92893 4.41891-2.92893 7.07107 0 2.6522 1.05357 5.1957 2.92893 7.0711.92859.9286 2.03098 1.6651 3.24424 2.1677 1.21325.5025 2.51363.7612 3.82683.7612 2.6522 0 5.1957-1.0536 7.0711-2.9289 1.8753-1.8754 2.9289-4.4189 2.9289-7.0711 0-1.3132-.2587-2.61358-.7612-3.82683-.5026-1.21326-1.2391-2.31565-2.1677-3.24424-.9286-.92858-2.031-1.66518-3.2443-2.16773-1.2132-.50254-2.5136-.7612-3.8268-.7612z"></path></svg>
                }
            </div>
            <div className="title">{state.texto}
                <div className="close" onClick={close}>
                    <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="m15.8333 5.34166-1.175-1.175-4.6583 4.65834-4.65833-4.65834-1.175 1.175 4.65833 4.65834-4.65833 4.6583 1.175 1.175 4.65833-4.6583 4.6583 4.6583 1.175-1.175-4.6583-4.6583z" fill="#393a37"></path></svg>
                </div>
            </div>
        </div>
    );
});

export default MensajeAlerta;
