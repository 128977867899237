import "./EditarUsuario.css"
import TextInput from "../../../components/TextInput/TextInput"
import { useState, useEffect } from "react"
import { getDatabase, ref, onValue, remove } from 'firebase/database';
import AlertMsg from "../../../components/AlertMsg/AlertMsg.jsx";
import Common from "../../../components/js/Common";
import appFirebase from "../../../firebase-config.js";
import ToggleButton from "../../../components/ToggleButton/ToggleButton.jsx";
import { getAuth, signInWithEmailAndPassword, updatePassword } from "firebase/auth";
import { useParams, useHistory } from "react-router-dom";
const auth = getAuth(appFirebase)


const EditarUsuario = () => {
    const { fk } = useParams();
    const history = useHistory();
    const [userDetails, setUserDetails] = useState({
        userName: "",
        password: "",
        email: "",
        name: "",
        apellidos: "",
        telefono: "",
        igualacion: 0,
        fastTrack: 0,
        ingresoResidual: 0,
        rangoResidual: 0,
        referenciaDirecta: 0,
        rango: 0,
        referido: "",
        walletCom: 0,
        walletDiv: 0,
        creditoCorpo: 0,
        fechaInicio: "",
        gananciasTotales: "",
        permisos: { promo: false, retiroDiv: true, retiroCom: true, matchingBono: true},
        deuda: 0
    });
    const [agregarDeuda, setAgregarDeuda] = useState(0);
    const [deudaActual, setDeudaActual] = useState(0);
    const [visible, setVisible] = useState(false);
    const [msj, setMsj] = useState("");
    const [visibleAlert, setVisibleAlert] = useState(false);
    const [visibleDetailsDeuda, setVisibleDetailsDeuda] = useState(true)

    useEffect(() => {
        const db = getDatabase();
        const userRef = ref(db, 'users/' + fk);

        const unsubscribe = onValue(userRef, (snapshot) => {
            const data = snapshot.val();
            if (data) {
                setDeudaActual(data.deuda)
                setUserDetails(prevState => ({ ...prevState, ...data }));
            } else {
                console.log("No such document!");
            }
        });

        return () => unsubscribe();
    }, [fk]);

    const handleChange = (key, value, isNumber) => {
        if (isNumber)
            value = Number(value)

        setUserDetails(prevDetails => ({ ...prevDetails, [key]: value }));
    };

    const editarUser = async () => {
        const common = new Common();
        const newDeuda = userDetails.deuda + Number(agregarDeuda)
        let updatedUser = {
            ...userDetails,
            password: await cambiarContraseña(userDetails.password),
            deuda: newDeuda
        };
        common.editAnyUser(updatedUser);
    };

    const cambiarContraseña = async (newPass) => {
        if (userDetails.password !== newPass) {
            const userCredential = await signInWithEmailAndPassword(auth, userDetails.email, userDetails.password);
            const userCurrent = userCredential.user;
            await updatePassword(userCurrent, newPass);
            return newPass;
        } else {
            return userDetails.password;
        }
    };

    const save = () => {
        try {
            editarUser();
            setVisible(true);
            setMsj("Changes made successfully");
        } catch (error) {
            setMsj("Error saving data");
        }
    };

    const eliminarUsuario = () => {
        /*const db = getDatabase();
        const userRef = ref(db, 'users/' + fk);

        remove(userRef)
            .then(() => {
                setVisible(true);
                setMsj("Successfully deleted user");
                history.push('/admin/gestionar-ususarios');
            })
            .catch((error) => {
                console.error('Error al eliminar el usuario: ', error);
            });*/
    };

    const regresar = () => {
        history.push('/admin/gestionar-ususarios');
    };

    const openCloseDetailsDeuda = () => {
        setVisibleDetailsDeuda(!visibleDetailsDeuda)
    }

    function DetallesDeDeuda() {

        return (
            <section className="details-deuda">
                <section className="details-deuda-contenido">
                    <header onClick={openCloseDetailsDeuda}>
                        <h1>Detalles</h1>
                        <button onClick={openCloseDetailsDeuda}><i class="bi bi-caret-down"></i></button>
                    </header>
                    {visibleDetailsDeuda && (
                        <>
                            <div className="deuda-detalles">
                                <div className="titulo"><i class="bi bi-person"></i><span>Detalles de deuda</span></div>
                                <div className="Modificar el capital">
                                    <h2>Agregar capital</h2>
                                    <div>
                                        <TextInput
                                            ti={"Modifica la deuda del ususario"}
                                            value={agregarDeuda}
                                            setValue={setAgregarDeuda}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="datosDeTranferencia">
                            </div>
                        </>
                    )}

                </section>
            </section>
        )
    }

    return (
        <section className="EditarUsuario">
            <AlertMsg visible={visible} setVisible={setVisible} texto={msj} />
            {visibleAlert && (
                <section className="deleteSection">
                    <div className="overlay-ads"></div>
                    <div className="deleteContain">
                        <div className="sec1-dc"><p>Eliminar al usuario: {userDetails.userName}</p></div>
                        <div className="sec2-dc">
                            <button onClick={() => setVisibleAlert(false)} className="cancel">Cancelar</button>
                            <button onClick={eliminarUsuario} className="ok">Aceptar</button>
                        </div>
                    </div>
                </section>
            )}
            <section className="botones">
                <div><button onClick={regresar}><p>Regresar</p></button></div>
                <div><button onClick={save}><p>Guardar</p></button></div>
                {/* <div><button onClick={() => setVisibleAlert(true)}><p>Eliminar usuario</p></button></div> */}
                <div><button onClick={eliminarUsuario}><p>Eliminar usuario</p></button></div>
            </section>
            <div><p className="titulo-edtu">Datos personales</p></div>
            <section className="enterData">
                {[
                    { label: "Nombre de usuario", key: "userName", isNumber: false },
                    { label: "Contraseña", key: "password", isNumber: false },
                    { label: "E-mail", key: "email", isNumber: false },
                    { label: "Nombre", key: "name", isNumber: false },
                    { label: "Apellidos", key: "apellidos", isNumber: false },
                    { label: "Telefono", key: "telefono", isNumber: false },
                    { label: "Direccion de wallet", key: "usdtAddress", isNumber: false },
                    { label: "Fecha de inscripción", key: "admissionDate", isNumber: false }
                ].map(input => (
                    <div key={input.key}>
                        <TextInput
                            ti={input.label}
                            value={userDetails[input.key]}
                            setValue={(value) => handleChange(input.key, value, input.isNumber)}
                        />
                    </div>
                ))}
            </section>
            <div><p className="titulo-edtu">Datos de oficinal virtual</p></div>
            <section className="enterData">
                {[
                    { label: "StaterPack", key: "staterPack", isNumber: true },
                    { label: "wallet de comisiones", key: "walletCom", isNumber: true },
                    { label: "wallet de dividendos", key: "walletDiv", isNumber: true },
                    { label: "Ganancias totales", key: "walletTotal", isNumber: true },
                    { label: "Retiros totales", key: "retiros", isNumber: true },
                    { label: "Bono fast-Track", key: "bonoFastTrack", isNumber: true },
                    { label: "Bono de igualación", key: "bonoIgualacion", isNumber: true },
                    { label: "Bono ingreso residual", key: "bonoIngresoRes", isNumber: true },
                    { label: "Bono rango residual", key: "bonoRangoRes", isNumber: true },
                    { label: "Bono referencia directa", key: "bonoRefDirect", isNumber: true },
                    { label: "Credito corporativo", key: "creditoCorpo", isNumber: true },
                    { label: "Deuda", key: "deuda", isNumber: true },
                    { label: "Rango", key: "rank", isNumber: true },
                    { label: "Referido", key: "referredBy", isNumber: false }
                ].map(input => (
                    <div key={input.key}>
                        <TextInput
                            ti={input.label}
                            value={userDetails[input.key]}
                            setValue={(value) => handleChange(input.key, value, input.isNumber)}
                        />
                    </div>
                ))}
            </section>
            <div><p className="titulo-edtu">Permisos</p></div>
            <section className="permisos">
                <div className="permiso">
                    <ToggleButton
                        permiso={userDetails.permisos.retiroDiv}
                        onToggle={(newValue) => handleChange("permisos", { ...userDetails.permisos, retiroDiv: newValue })}
                    />
                    <span>Retirar de dividendos</span>
                </div>
                <div className="permiso">
                    <ToggleButton
                        permiso={userDetails.permisos.retiroCom}
                        onToggle={(newValue) => handleChange("permisos", { ...userDetails.permisos, retiroCom: newValue })}
                    />
                    <span>Retirar de Comisiones</span>
                </div>
                <div className="permiso">
                    <ToggleButton
                        permiso={userDetails.permisos.promo}
                        onToggle={(newValue) => handleChange("permisos", { ...userDetails.permisos, promo: newValue })}
                    />
                    <span>Activar promocion de 10%</span>
                </div>
                <div className="permiso">
                    <ToggleButton
                        permiso={userDetails.permisos.matchingBono}
                        onToggle={(newValue) => handleChange("permisos", { ...userDetails.permisos, matchingBono: newValue })}
                    />
                    <span>Matching bono</span>
                </div>
            </section>

            <section className="creditoEmpresarial">
                <div><p className="titulo-edtu">Prestamo corporativo</p></div>
                <div className="permiso">{/* 
                    <ToggleButton
                        permiso={userDetails.permisos.creditoCorpo}
                        onToggle={(newValue) => handleChange("permisos", { ...userDetails.permisos, creditoCorpo: newValue })}
                    /><span>Activar</span> */}
                </div>
                <div className="detallesAdeudo">
                    <h1>Adeudo Actual:<span> {deudaActual || 0} USDT</span></h1>
                </div>
                {DetallesDeDeuda()}
            </section>
        </section>
    );
};

export default EditarUsuario;
