import { useEffect, useState } from "react";
import AdminData from "../Admin/AdminData";

const AprobarPagos = () => {
    const [userModels, setUserModels] = useState([]);
    const [textFind, setTextFind] = useState("");

    useEffect(() => {
        const adminData = new AdminData()
        adminData.fetchData().then(peticiones => {
            setUserModels(peticiones)
        })
    }, [])

    const aprobar = async (aprobado, peticion) => {
        const adminData = new AdminData()
        if (aprobado)
            await adminData.aprobar(peticion)
        else
            await adminData.denegar(peticion.firebaseKey)

        await adminData.fetchData()
    }

    return (
        <>
            <div className="tabla">
                <div className="encabezado">
                    <h2>Pagos pendiente</h2>
                    <div className="buscador">
                        <input type="text" placeholder="Buscar por E-mail..." onChange={(e) => setTextFind(e.target.value)} />
                        {/*                         <button onClick={() => adminData.findData(textFind)}><i class="bi bi-search"></i></button>
 */}                    </div>
                    {/*                     <button onClick={() => adminData.fetchData()} className="refresh" ><i class="bi bi-arrow-counterclockwise"></i></button>
 */}                </div>
                <li>
                    <span className="column-header">Nombre de usuario</span>
                    <span className="column-header">E-mail</span>
                    <span className="column-header">Cantidad</span>
                    <span className="column-header">Concepto</span>
                    <span className="column-header">Aprobar</span>
                </li>
                <div className="datos">
                    <ul>
                        {userModels.map((item, index) => (
                            <li key={index}>
                                <span>{item.userName}</span>
                                <span>{item.email}</span>
                                <span>{item.monto}</span>
                                <span>{item.concepto}</span>
                                <div className="aprobar">
                                    <button onClick={() => aprobar(true, item)} className="check">
                                        <i class="bi bi-check-circle-fill" />
                                    </button>
                                    <button onClick={() => aprobar(false, item)} className="trash">
                                        <i class="bi bi-x-circle-fill" />
                                    </button>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </>
    )
}

export default AprobarPagos