import logoUSDT from "../../../Assets/Images/Logos/usdt.png";
import "./Retiros.css";
import TextInput from "../../../components/TextInput/TextInput";
import { Link, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import AlertMsg from "../../../components/AlertMsg/AlertMsg";
import AlertMsgError from "../../../components/AlertMsg/AlertMsgError";
import { getDatabase, ref, onValue, get, query, orderByChild, equalTo } from "firebase/database";
import NipModal from "../../../components/NipModal/NipModal";
import appFirebase from "../../../firebase-config";
import Common from "../../../components/js/Common";
import PeticionModel from "../../../model/PeticionModel"
import img1 from "../../../Assets/Images/qr/qrms.png"

const PagarFacturas = () => {
    const [code, setCode] = useState("")

    const [texto, setTexto] = useState("")
    const [visible, setVisible] = useState(false)
    const [visibleError, setVisibleError] = useState(false)

    const [seleccionado, setSeleccionado] = useState(1)
    const [isLoading, setIsLoading] = useState(1)
    const [loading, setLoading] = useState(true)

    const [userData, setUserData] = useState([])
    const [propietario, setPropietario] = useState([])
    const [peticion, setPeticion] = useState([])

    useEffect(() => {
        fetchUserData()
    }, [])

    function fetchUserData() {
        const userRepo = new Common()
        userRepo.fetchUserData().then(user => {
            setUserData(user)
            setLoading(false)
        })
    }

    const elegirAccion = () => {
        if (isLoading == 3) {
            pagarFactura()
        } else if (isLoading == 1) {
            encontrarFactura()
        }
    }

    const encontrarFactura = () => {
        if (!validacion()) return

        setIsLoading(2)

        const peticionRepo = new PeticionModel()
        const userRepo = new Common()
        peticionRepo.getFactura(code).then(peticion => {
            setPeticion(peticion)
            userRepo.fetchUserDataByName(peticion.email).then(user => {
                setPropietario(user)
                setIsLoading(3)
            })
        })
    }

    const pagarFactura = () => {
        const wallet = seleccionado == 1 ? userData.walletDiv : userData.walletCom

        if (!validacion(wallet)) return

        if (seleccionado == 1) {
            userData.walletDiv -= peticion.monto * 0.5
        } else {
            userData.walletCom -= peticion.monto * 0.5
        }

        const userRepo = new Common()
        const peticionRepo = new PeticionModel("Bill payment by transfer", peticion.monto * .5, "")
        userRepo.editAnyUser(userData).then(() => {
            peticionRepo.save()
            //peticionRepo.borrar(peticion.firebaseKey)
            fetchUserData()
            setCode("")
            setIsLoading(1)
            setTexto("Invoice paid")
            setVisible(true)
        })
    }

    const openCloseNipModal = () => {

    }

    const validacion = (wallet) => {
        const errores = {
            codeNotFound: "Code not found",
            insufficientBalance: "You do not have enough balance to withdraw this amount",
            minBalanceAfterWithdraw: "After withdrawing you need to have at least 25 USDT remaining",
        }
        const mostrarError = (mensaje) => {
            setVisibleError(true);
            setTexto(mensaje);
            return false;
        };
        if (code == "" || !code) {
            return mostrarError(errores.codeNotFound)
        }
        if (wallet < peticion.monto * .5) {
            return mostrarError(errores.insufficientBalance)
        }
        if (seleccionado == 1 && Number(wallet - peticion.monto * .5) < 25) {
            return mostrarError(errores.minBalanceAfterWithdraw)
        }
        return true
    }

    const changeSeleccion = (opc) => {
        setSeleccionado(opc)
    }

    const handleCopy = () => {
        const inputElement = document.getElementById("wallet");
        inputElement.select();
        document.execCommand('copy');
        window.getSelection().removeAllRanges();
        setTexto("Address copied successfully")
        setVisible(true)
    };

    return (
        <section className="contenido Retiros">
            {loading ? (
                <div className="spinner"></div>
            ) : (
                <>
                  
                    <AlertMsgError texto={texto} visible={visibleError} setVisible={setVisibleError} />
                    <AlertMsg texto={texto} visible={visible} setVisible={setVisible} />
{/*                     <NipModal correctNip={userData.nip} onOpenClose={openCloseNipModal} modalNip={visibleNipModal} />
 */}                    <section className="titulos titulo-re">
                        <i class="bi bi-receipt"></i>
                        <span>Facturas</span>
                    </section>

                    <section className="contenido">
                        <section className="retirar">
                            <p className="titulo">Select your wallet</p>
                            <div className="wallets">
                                {WalletOpcion("Divident wallet", userData.walletDiv, 1)}
                                {WalletOpcion("Comission wallet", userData.walletCom, 2)}
                            </div>

                            <TextInput ti={"Billing code"} value={code} setValue={setCode} />
                            <button className="boton4" onClick={elegirAccion}><span>{isLoading == 1 ? ("Find invoice") : ("Pay Bill")}</span></button>
                        </section>

                        <section className="DatosFactura">
                            <h2>Invoice details</h2>
                            {isLoading == 1 && (
                                <>
                                    <p>No details</p>
                                </>
                            )}
                            {isLoading == 2 && (
                                <>
                                    <div className="spinner"></div>
                                </>
                            )}
                            {isLoading == 3 && (
                                <>
                                    <p>Owner: <span>{propietario.firstName + " " + propietario.lastName}</span></p>
                                    <p>Username: <span>{propietario.userName}</span></p>
                                    <p>Maximum amount to pay: <span>{peticion.monto * 0.5}</span></p>
                                    <p>Account Total: <span>{peticion.monto || 0.00}</span></p>
                                </>
                            )}

                        </section>
                    </section>

                    <section className="qrPagoFac">
                        <p className="titulo">Pago con QR</p>
                        <div className="qr-section">
                            <div className="contenidoqrpf">
                                <section className="notas">
                                    <p className="textoM"><li>Scan the QR code to pay or copy the wallet address into your preferred application.</li></p>
                                </section>
                                <div className="copyAddrs">
                                    <p >Wallet address to pay</p>
                                    <div className="wallet">
                                        <input type="text" id="wallet" readOnly value="TMuMJUSBamBf1d2vhbd4g1p13pUf6N7TtM" />
                                        <button onClick={handleCopy} ><i class="bi bi-copy"></i></button>
                                    </div>
                                </div>
                            </div>
                            <div className="qrToPay">
                                <p className="titulo">Qr code to pay</p>
                                <img src={img1} alt="QR code" />
                            </div>
                        </div>

                    </section>
                </>
            )}


        </section >


    )

    function WalletOpcion(titulo, wallet, opc) {
        return (
            <div onClick={() => changeSeleccion(opc)} className={seleccionado == opc ? "seleccionado walletOpcion" : "walletOpcion"}>
                <h3>{titulo}</h3>
                <div>
                    <img src={logoUSDT} alt="usdt_logo" />
                    <span>{(wallet || 0).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(',', '.')}</span>
                </div>
            </div>
        )
    }
}



export default PagarFacturas